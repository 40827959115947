<template>
  <div class="abstract-data-table__td">
    <span v-if="header" class="abstract-data-table__td-desc">{{ header }}</span>
    <IconCheck v-if="check" class="abstract-data-table__td-check-icon" />
  </div>
</template>

<script>
import IconCheck from '@/assets/img/svg/check.svg?inline'

export default {
  name: 'CheckColumn',
  props: {
    header: {
      type: String,
      required: true
    },
    check: {
      type: Boolean,
      required: true
    }
  },
  components: {
    IconCheck
  }
}
</script>

<style scoped lang="scss">
  .abstract-data-table {
    &__td-check-icon {
      @include size(18px);

      path:first-of-type {
        fill: #5AB963;
      }
      path:nth-of-type(2n){
        fill: #ffffff;
      }
    }
  }
</style>
