<template>
  <section>
    <ButtonCreate class="create-button" route-name="oneSignal_new" />
    <Preloader v-if="callingAPI" />
    <OneSignalTable
      :items="list"
      :totalCount="totalCount"
      :current-page="page"
      :on-page-change="onPageChange"
    />
  </section>
</template>

<script>
import { mapState } from 'vuex'
import Preloader from '@/components/preloader/Preloader'
import OneSignalTable from '@/components/table/OneSignalTable'
import ButtonCreate from '@/components/shared/ButtonCreate'

export default {
  name: 'OneSignalListView',
  components: {
    OneSignalTable,
    Preloader,
    ButtonCreate
  },
  computed: {
    ...mapState([
      'callingAPI'
    ]),
    totalCount () {
      return this.$store.getters['oneSignal/totalCount']
    },
    page () {
      return this.$store.getters['oneSignal/page']
    },
    list () {
      return this.$store.getters['oneSignal/list']
    }
  },
  methods: {
    async onPageChange (page) {
      this.$store.commit('oneSignal/setPage', page)
      await this.$store.dispatch('oneSignal/fetch')
    }
  },
  async created () {
    await this.$store.dispatch('oneSignal/fetch')
  }
}
</script>
<style scoped lang="scss">
.create-button {
  width: max-content;
  margin-left: auto;
  margin-bottom: 1rem;
}
</style>
